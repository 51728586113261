import React from "react";

/**
 * @returns {boolean}
 */
export const isBrowser = () => {
    return (typeof window !== "undefined")
}

/**
 * @param breakpoint
 * @returns {boolean}
 */
export const isMobile = (breakpoint = 'md') => {
    if (!isBrowser()) {
        return false
    }

    let viewportWidth = window.innerWidth

    // @see https://tailwindcss.com/docs/breakpoints
    let screens = {
        'sm': 640,
        'md': 768,
        'lg': 1024,
        'xl': 1280,
        '2xl': 1536,
    }

    let usableBreakpoint = screens.hasOwnProperty(breakpoint) ? screens[breakpoint] : screens.md

    return viewportWidth < usableBreakpoint
}

/**
 * Return a clean css class based on provided text
 *
 * @param text
 * @returns {string}
 */
export const cssCleanClass = (text) => {
    return text.toLowerCase()
      .replace(/[^\w ]+/g,'')
      .replace(/ +/g,'-')
    ;
}

/**
 * Wrap text in sr only class
 * @param children
 * @returns {JSX.Element}
 */
export const iconInvisibleText = (children) => (
    <span className="icon-explain md:hidden">{children}</span>
)

/**
 * Return font awesome icon name matching extension
 *
 * @param extension
 * @returns {string}
 */
export const getIconFromExtension  = (extension) => {
    let fileTypeIconMatch = {
        'file-word': ['doc', 'odf', 'odt', 'docx'],
        'file-excel': ['ods', 'xls', 'xlsx'],
        'file-image': ['png', 'gif', 'jpg', 'jpeg', 'odt'],
        'file-archive': ['tar', 'gz', 'zip'],
        'file-pdf': ['pdf'],
    }

    for (let iconName in fileTypeIconMatch) {
        if (fileTypeIconMatch[iconName].includes(extension)) {
            return iconName
        }
    }

    return 'file'
}

/**
 * Take a drupal menu graphql query result
 * Return a sorted Map based on depth and weight
 *
 * @param nodes
 * @returns {Map<unknown, unknown>}
 */
export const drupalMenuSorter = (nodes) => {
    let menuItems = new Map()
    let subMenuItems = new Map()

    let findChildren = ({children}, index, subMenuItems, level) => {
        subMenuItems.forEach((element, elementIndex) => {
            let parentId = element.item.drupal_parent_menu_item.split(':')[1]

            if (parentId === index) {
                element.level = level
                children.set(elementIndex, element)
                subMenuItems.delete(elementIndex)
                findChildren(element, elementIndex, subMenuItems, level + 1)
            }
        })
    }

    // Split top menu level and sub-menu items
    nodes.forEach(({ node }) => {
        if (!node.drupal_parent_menu_item) {
            menuItems.set(node.drupal_id, {
                item: node,
                children: new Map(),
                level: 0,
            })
        } else {
            subMenuItems.set(node.drupal_id, {
                item: node,
                children: new Map(),
                level: undefined,
            })
        }
    })

    // Place sub-menu items
    menuItems.forEach((parent, parentIndex) => {
        findChildren(parent, parentIndex, subMenuItems, 1)
    })

    // Menu order
    return new Map([...menuItems.entries()].sort((a, b) => {
        return a[1].item.weight - b[1].item.weight
    }));
}