import React from "react";
import ReactHtmlParser, {convertNodeToElement} from "react-html-parser"
import Iframe from "react-iframe";

import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

const HtmlParser = ({ html }) => {
    const data = useStaticQuery(graphql`
        {
            images: allFileFile(
                filter: {localFile: {extension: {in: ["jpg", "jpeg", "png", "gif"]}}}
            ) {
                edges {
                    node {
                        drupal_id
                        localFile {
                            childImageSharp {
                                original {
                                  height
                                  width
                                }
                                fluid(maxWidth: 1024) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
            files: allFileFile(
                filter: {localFile: {extension: {in: ["pdf", "odt", "docx", "xls", "xlsx"]}}}
            ) {
                edges {
                    node {
                        localFile {
                            base
                            publicURL
                        }
                    }
                }
            }
        }
    `)

    const getFileFromUrl = new RegExp("(?=\\w+\\.\\w{3,4}$).+")

    let ParsedHtml = new ReactHtmlParser(html, {
        transform: function transform(node, index) {
            if (node.type === 'tag') {
                switch (node.name) {
                    case 'a':
                        let href = node.attribs["href"]
                        let match = href.match(getFileFromUrl)

                        if (null !== match) {
                            let filename = match[0]

                            for (let i = 0; i < data.files.edges.length; i++) {
                                if (data.files.edges[i].node.localFile.base === filename) {
                                    node.attribs['href'] = data.files.edges[i].node.localFile.publicURL
                                    node.attribs['download'] = true // Force download because file path is dirty
                                    return convertNodeToElement(node, index, transform);
                                }
                            }
                        }
                        break;
                    case 'img':
                        let uuid = node.attribs["data-entity-uuid"]

                        for (let i = 0; i < data.images.edges.length; i++) {
                            if (data.images.edges[i].node.drupal_id === uuid
                                && data.images.edges[i].node.localFile) {
                                let childImageSharp = data.images.edges[i].node.localFile.childImageSharp;
                                let width = (node.attribs["width"] ? node.attribs["width"] : childImageSharp.original.width)
                                let height = (node.attribs["height"] ? node.attribs["height"] : childImageSharp.original.height)

                                return (
                                    <div
                                        className="inline-image" {...node.attribs}
                                        style={{width: Math.min(width, 1024) + 'px', height: Math.min(height, 1024) + 'px'}}
                                    >
                                        <Img
                                            fluid={childImageSharp.fluid}
                                        />
                                    </div>
                                )
                            }
                        }
                        break;
                    case 'iframe':
                        let url = node.attribs.src;

                        if (url.search('http') === -1) {
                            if (url.search('//') === -1) {
                                url = "//" + url
                            }

                            url = "https:" + url
                        }

                        let id = url.split("/").pop()

                        return <Iframe
                            url={url}
                            width={node.attribs.width}
                            height={node.attribs.height}
                            id={id}
                            display="initial"
                            position="relative"
                            allowFullScreen
                        />
                }
            }

            return undefined;
        }
    });

    return (
        <>
            {ParsedHtml}
        </>
    )
}

HtmlParser.propTypes = {
    html: PropTypes.node.isRequired
}

export default HtmlParser
