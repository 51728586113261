import React from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";
import HtmlParser from "../HtmlParser";

const ContactInfos = ({title, titleDisplay, titleSize}) => {
  const data = useStaticQuery(graphql`{
      block: blockContentBasic(drupal_internal__id: {eq: 1}) {
          info
          body {
              value
          }
      }
  }`)

  return (
    <>
      {titleDisplay && (
        <h2 className={`title is-uppercase h${titleSize} mb-4`}>{title
          ? title
          : data.block.info}</h2>
      )}
      <div className="content">
        <HtmlParser html={data.block.body.value}/>
      </div>
    </>
  )
}

ContactInfos.propTypes = {
  title: PropTypes.string,
  titleDisplay: PropTypes.bool,
  titleSize: PropTypes.number,
}

ContactInfos.defaultProps = {
  title: '',
  titleDisplay: true,
  titleSize: 3,
}

export default ContactInfos
