import React, {createContext, useEffect, useState} from 'react';

export const ModalContext = createContext({})

const ModalProvider = ({children}) => {
    const [modalVisibility, setModalVisibility] = useState(false)
    const [modalContent, setModalContent] = useState(null)
    const [modalKeyDownEvent, setModalKeyDownEvent] = useState(null)

    return (
        <ModalContext.Provider value={{modalVisibility, setModalVisibility, modalContent, setModalContent, modalKeyDownEvent, setModalKeyDownEvent}}>
            {children}
        </ModalContext.Provider>
    )
}

export default ModalProvider