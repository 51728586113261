import React, {useEffect, useState} from "react";
import {InstantSearch, connectStateResults} from "react-instantsearch-dom";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import CustomAutocomplete from "./Search/CustomAutocomplete";
import CustomHits from "./Search/CustomHits";

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
    server: {
        apiKey: process.env.TYPESENSE_API_KEY,
        nodes: [
            {
                host: process.env.TYPESENSE_HOST,
                port: process.env.TYPESENSE_PORT,
                protocol: process.env.TYPESENSE_PROTOCOL,
            },
        ],
    },
    additionalSearchParameters: {
        queryBy: "title,body,paragraph_text,paragraph_highlighted",
    },
})

const searchClient = typesenseInstantsearchAdapter.searchClient

const Results = connectStateResults(
    ({searchState, searchResults, children}) => {
        if (searchState && searchState.query) {
            if (searchResults && searchResults.nbHits !== 0) {
                return (
                    <div className="p-2">{children}</div>
                )
            } else {
                return (
                    <div className="p-2">
                        <p className="p-2">Aucun résultat ne correspond à votre recherche.</p>
                    </div>
                )
            }
        } else {
            return (
                <></>
            )
        }
    }
);

export default function SearchBar() {
    const [opened, setOpened] = useState(false);
    const [focused, setFocused] = useState(false);
    const [search, setSearch] = useState('');

    useEffect(() => {
        document.body.addEventListener('click', (event) => {
            if (event.target.id == 'search-bar' || event.target.closest('#search-bar') != null) {
                setFocused(true)
            } else {
                setFocused(false)
                setOpened(false)
            }
        })
    }, [])

    useEffect(() => {
        if (focused) {
            document.getElementById("search-autocomplete-input").focus()
        }
    }, [focused])

    return (
        <>
            <div className="search-toggle flex items-center justify-end md:hidden h-full">
                <button
                    className={`search-toggle-button p-6 sm:p-8 text-white text-xl h-full hover:bg-primary-light transition-all${opened ? ` opened` : ``}`}
                    aria-expanded={opened ? `true` : `false`}
                    onClick={() => {
                        setOpened(!opened)
                    }}
                    style={{borderRadius: '0'}}
                >
                    <i className="fas fa-search"><span className="hidden">Recherche</span></i>
                </button>
            </div>
            <div
                className={`search-autocomplete-wrapper md:h-full absolute md:relative inset-x-0 ${opened ? ` block` : ` hidden`} md:block${(focused || opened) ? ` bg-primary-light` : ` bg-transparent`}`}>
                <InstantSearch searchClient={searchClient} indexName="rancennes_content">
                    <CustomAutocomplete opened={opened} focused={focused} />
                    <div
                        className={`search-autocomplete-result absolute inset-x-0 text-white bg-primary-light${focused ? ` block` : ` hidden`}`}>
                        <Results>
                            {/*<Stats />*/}
                            <CustomHits/>
                            {/*<RefinementList attribute="content_type" />*/}
                        </Results>
                    </div>
                </InstantSearch>
            </div>
        </>
    )
}
