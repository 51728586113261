import React from "react";
import PropTypes from "prop-types";
import SearchBar from "./SearchBar";
import Navigation from "./Navigation";
import Branding from "./Branding";

const Hero = ({isHome}) => {
    return (
        <section className="relative hero h-72 md:h-96 z-50">
            <div className="absolute inset-0">
                <img
                    className="h-full w-full object-cover"
                    src="/background.jpg"
                    alt="Bienvenue sur notre site"
                />
                <div className="absolute inset-0 bg-gradient-to-b from-primary via-white to-white mix-blend-multiply"/>
            </div>

            <div className="container mx-auto md:px-2 md:h-full">
                <div className="hero-content md:pt-12 md:h-full top-0 inset-x-0 fixed md:static flex md:flex-col md:justify-between bg-primary md:bg-transparent shadow-lg md:shadow-none">
                    <div className="hero-header static md:relative flex-1 z-50">
                        <div className="flex static md:relative">
                            <div className="flex-initial">
                                <Branding isHome={isHome} logo title isNavbar logoHeight={"104px"} logoWidth={"80px"}/>
                            </div>
                            <div id="search-bar" className="flex-1">
                                <SearchBar/>
                            </div>
                        </div>
                    </div>

                    <div className="hero-footer static md:relative flex-initial md:transform md:translate-y-1/2">
                        <Navigation id={`main-navigation`}/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero
