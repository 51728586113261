import { Link } from "gatsby";
import React from "react";
import EntityLink from "./EntityLink";

const MenuLink = ({children, internalId, attributes, clickHandler, ...props}) => {
  let pathInfo = internalId.split(':')

  if (props.className === undefined) {
    props.className = ''
  }

  if (attributes && attributes.class) {
    props.className += attributes.class.join(' ');
  }

  return (
      <>
        {pathInfo[0] === 'entity' ? (
            // Entity link
            <EntityLink
                internalId={internalId}
                onClick={clickHandler}
                {...props}
            >
              {children}
            </EntityLink>
        ) : pathInfo[0] === 'internal' ? (
            // Internal link (content list or #)
            <Link
                to={pathInfo[1]}
                onClick={clickHandler}
                {...props}
            >
              {children}
            </Link>
        ) : (pathInfo[0] === 'mailto' || pathInfo[0] === 'tel') ? (
            <a
                href={internalId}
                {...props}
            >
              {children}
            </a>
        ) : (
            // External link
            <Link
                to={internalId}
                onClick={clickHandler}
                target="_blank"
                rel="noreferrer"
                {...props}
            >
              {children}
            </Link>
        )}
      </>
  )
}

export default MenuLink