import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import {graphql, useStaticQuery} from 'gatsby';

function Metas({
  description,
  lang,
  meta,
  title,
  image,
  imageHeight,
  imageWidth,
}) {
  const {site} = useStaticQuery(
      graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    author
                    image
                    imageWidth
                    imageHeight
                }
            }
        }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
      <Helmet
          htmlAttributes={{
            lang,
          }}
          title={title}
          titleTemplate={`%s | ${site.siteMetadata.title}`}
          meta={[
            {
              name: `description`,
              content: metaDescription,
            },
            {
              property: `og:title`,
              content: title,
            },
            {
              property: `og:description`,
              content: metaDescription,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              property: `og:image`,
              content: image || site.siteMetadata.image,
            },
            {
              property: `og:image:width`,
              content: imageWidth || site.siteMetadata.imageWidth,
            },
            {
              property: `og:image:height`,
              content: imageHeight || site.siteMetadata.imageHeight,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
            {
              name: `twitter:creator`,
              content: site.siteMetadata.author,
            },
            {
              name: `twitter:title`,
              content: title,
            },
            {
              name: `twitter:description`,
              content: metaDescription,
            },
            {
              name: `google-site-verification`,
              content: 'jNg1GElVG8zEpo8x20OY6qpx_3TsUQn1eFWIXzApIrI',
            },
          ].concat(meta)}
      />
  );
}

Metas.defaultProps = {
  lang: `fr`,
  meta: [],
  description: ``,
};

Metas.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default Metas;
