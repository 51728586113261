import React from "react";
import { connectAutoComplete } from "react-instantsearch-dom";

const Autocomplete = ({ hits, currentRefinement, refine, opened, focused }) =>  (
  <div className={`search-autocomplete relative h-full flex items-center justify-end p-4${opened ? ` opened` : ``}${focused ? ` focused` : ``}`}>
      <label htmlFor="search-autocomplete-input" className="hidden">Recherche</label>
      <input
        id="search-autocomplete-input"
        name="search-autocomplete-input"
        type="search"
        value={currentRefinement}
        onChange={event => refine(event.currentTarget.value)}
        className="w-full pl-2 pr-2 md:pr-8 py-2 bg-transparent text-xl md:text-right font-semibold placeholder-white text-white border-b border-transparent focus:outline-none"
        placeholder="Recherche"
        autoComplete="off"
      />
      <span className="absolute top-1/2 right-4 transform -translate-y-1/2 right-0 search-autocomplete-icon ml-2 px-2">
          <i className="fas fa-search text-xl text-white">
              <span className="hidden">Search</span>
          </i>
      </span>
  </div>
)

export default connectAutoComplete(Autocomplete);
