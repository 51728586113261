import React, {useState} from "react";
import MainMenu from "./Menus/MainMenu";

const Navigation = ({color, spaced, ...props}) => {
    const [opened, setOpened] = useState(false)

    if (props.className === undefined) {
        props.className = ''
    }

    props.className += ' navbar static h-full'

    if (color !== undefined) {
        props.className += ' is-' + color
    }

    if (spaced) {
        props.className += ' is-spaced'
    }

    return (
        <nav {...props}>
            <div className="navbar-brand flex items-center justify-center md:hidden h-full">
                <button
                    className={`navbar-burger burger h-full p-6 sm:p-8 text-white text-xl transition-all hover:bg-primary-light${opened ? ` opened` : ``}`}
                    aria-label="menu"
                    aria-expanded={opened ? `true` : `false`}
                    onClick={() => {
                        setOpened(!opened)
                    }}
                    style={{borderRadius: '0'}}
                >
                    <i className="fas fa-bars"><span className="hidden">Menu</span></i>
                </button>
            </div>
            <div id="navbarCollapsible"
                 className={`navbar-menu inset-x-0 absolute md:static md:block${opened ? ` block` : ` hidden`}`}>
                <div className="navbar-menu-wrapper">
                    <MainMenu/>
                </div>
            </div>
        </nav>
    )
}

export default Navigation
