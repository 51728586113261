import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import {cssCleanClass} from '../../Utils/functions';

const HitsContentType = ({contentType}) => {
    var displayType = contentType;
    var [typeType, machineName] = contentType.split('__');

    const nodeTypesData = useStaticQuery(graphql`{
      nodeTypes: allNodeTypeNodeType {
        nodes {
          machineName: drupal_internal__type
          name
        }
      }
    }
  `);

    switch (typeType) {
        case 'node':
            for (var index in nodeTypesData.nodeTypes.nodes) {
                if (machineName ===
                    nodeTypesData.nodeTypes.nodes[index].machineName) {
                    displayType = nodeTypesData.nodeTypes.nodes[index].name;
                }
            }
            break;
        case 'portal':
            displayType = "Portail"
            break;
    }

    return (
        <small
            className={`inline-block text-primary font-semibold italic${typeType 
                ? ` type-${cssCleanClass(typeType)}` 
                : ``}`}
        >
            {displayType}
        </small>
    );
};

export default HitsContentType;
