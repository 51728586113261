import React from 'react';
import PropTypes from 'prop-types';
import {graphql, Link, useStaticQuery} from 'gatsby';
import Logo from '../images/logo.svg';

const Branding = ({
                      isHome,
                      logo,
                      logoHeight,
                      logoWidth,
                      title,
                      description,
                      isNavbar,
                  }) => {

    const data = useStaticQuery(graphql`{
        site {
            siteMetadata {
                logo
                title
                description
            }
        }
    }`)

    const sizedLogo = (children) => (
        <div className="p-1 bg-white" style={{height: logoHeight, width: logoWidth}}>
            {children}
        </div>
    )

    return (
        <>
            {(logo && (title || description)) ? (
                <div className="flex bg-primary text-white h-full">
                    {isHome ? (
                        <h1 className="flex-initial">
                            {logo && (
                                <Link className="site-logo" to="/" title={data.site.siteMetadata.title}>
                                    {(logoHeight || logoWidth) ? sizedLogo(
                                        <Logo src={data.site.siteMetadata.logo} className="img" alt={data.site.siteMetadata.title}/>
                                    ) : (
                                        <Logo src={data.site.siteMetadata.logo} className="img" alt={data.site.siteMetadata.title}/>
                                    )}
                                </Link>
                            )}
                        </h1>
                    ) : (
                        <div className="flex-initial">
                            {logo && (
                                <Link className="site-logo" to="/" title="Accueil">
                                    {(logoHeight || logoWidth) ? sizedLogo(
                                        <Logo src={data.site.siteMetadata.logo} className="img" alt={data.site.siteMetadata.title}/>
                                    ) : (
                                        <Logo src={data.site.siteMetadata.logo} className="img" alt={data.site.siteMetadata.title}/>
                                    )}
                                </Link>
                            )}
                        </div>
                    )}

                    <div
                        className={`flex-initial flex-col justify-center items-center p-4 sm:px-8 sm:py-4${isNavbar ? ` hidden md:flex` : ` flex`}`}>
                        {title && (
                            <Link
                                className={`${isNavbar ? `navbar-item ` : ``}site-name text-white font-semibold leading-4 uppercase`}
                                to="/" title="Accueil">
                                Commune <span className="block">de <span
                                className="font-handwritten normal-case text-3xl">Rancennes</span></span>
                            </Link>
                        )}
                        {description && (
                            <Link className={`${isNavbar
                                ? `navbar-item is-hidden-mobile `
                                : ``}site-description`} to="/" title="Accueil">
                                <div className="subtitle">{data.site.siteMetadata.description}</div>
                            </Link>
                        )}
                    </div>
                </div>
            ) : (
                <>
                    {logo && (
                        <>
                            {isHome ? (
                                <h1>
                                    <Link className={`${isNavbar ? `navbar-item ` : ``}site-logo`} to="/" title="Accueil">
                                        {(logoHeight || logoWidth) ? sizedLogo(
                                            <Logo src={data.site.siteMetadata.logo} className="img" alt={data.site.siteMetadata.title}/>
                                        ) : (
                                            <Logo src={data.site.siteMetadata.logo} className="img" alt={data.site.siteMetadata.title}/>
                                        )}
                                    </Link>
                                </h1>
                            ) : (
                                <Link className={`${isNavbar ? `navbar-item ` : ``}site-logo`} to="/" title="Accueil">
                                    {(logoHeight || logoWidth) ? sizedLogo(
                                        <Logo src={data.site.siteMetadata.logo} className="img" alt={data.site.siteMetadata.title}/>
                                    ) : (
                                        <Logo src={data.site.siteMetadata.logo} className="img" alt={data.site.siteMetadata.title}/>
                                    )}
                                </Link>
                            )}
                        </>

                    )}
                </>
            )}
        </>
    )
}

Branding.propTypes = {
    isHome: PropTypes.bool,
    logo: PropTypes.bool,
    logoHeight: PropTypes.string,
    logoWidth: PropTypes.string,
    title: PropTypes.bool,
    description: PropTypes.bool,
    isNavbar: PropTypes.bool,
}

Branding.defaultProps = {
    isHome: false,
    logo: false,
    title: false,
    description: false,
    isNavbar: false,
}

export default Branding
