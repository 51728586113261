import React from "react";
import PropTypes from "prop-types";
import "../styles/index.scss";
import Hero from "./Hero";
import FooterMenu from "./Menus/FooterMenu";
import ContactInfos from "./Blocks/ContactInfos";
import OpeningHours from "./Blocks/OpeningHours";
import { Flash } from "./Flash";
import Branding from "./Branding";
import ModalProvider from "../contexts/ModalContext";
import Modal from "./Modal/Modal";

const Layout = ({children, isHome, contentType = null }) => {
  return (
    <ModalProvider>
      {contentType && (
        <div data-typesense-field="content_type" style={{display: 'none'}}>{contentType}</div>
      )}
      <Hero isHome={isHome}/>
      <main id="main-content" className={`md:pt-12 lg:pt-24${isHome ? ` shape-bg` : ``}`} role="main">
        <div id="content--region" className="region content--region">
          {children}
          <Flash />
        </div>
      </main>
      <footer id="footer" className="footer section bg-primary text-white">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="column">
              <div className="inline-block">
                <Branding logo title logoHeight={"104px"} logoWidth={"80px"}/>
              </div>
            </div>
            <div className="column">
              <ContactInfos titleSize={5}/>
            </div>
            <div className="column">
              <OpeningHours titleSize={5}/>
            </div>
          </div>
          <div className="region md:text-center mt-16">
            <FooterMenu/>
          </div>
        </div>
      </footer>
      <Modal/>
    </ModalProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isHome: PropTypes.bool,
  typesense: PropTypes.bool
}

Layout.defaultProps = {
  isHome: false,
  typesense: false
}

export default Layout
